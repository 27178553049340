import { Form, Input, Button } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { login}  from 'src/apis'
import { useNavigate} from 'react-router-dom'
import { useDispatch, useSelector } from 'src/hooks'
import { setIsLogged, setUserInfo } from 'src/pages/loginPage/loginPageSlice'
import logo from 'src/assets/img/logo.png'
import styles from './index.module.scss'
import { useEffect } from 'react'

const LoginPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [form] = Form.useForm();

  const { isLogged } = useSelector((state) => state.login)
  
  useEffect(()=>{
    if(isLogged){
      navigate('/workbench')
    }
  },[isLogged, navigate])

  const onFinish = (values: any) => {
    const params = {...values}
    login(params).then(res=>{
      if(res){
        dispatch(setIsLogged(true))
        dispatch(setUserInfo(res))
        navigate('/workbench')
      }
    }).catch(err=>{console.error('登录失败：', err)})
  }
  return (
    <div className={styles.loginPageWrap}>
      <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.loginCard}>
            <div className={styles.header}>
              <img alt='' src={logo} />
            </div>
            <Form
              className='tc'
              form={form}
              autoComplete="off"
              onFinish={onFinish} 
            >
              <Form.Item
                name="username"
                rules={[{ required: true }]}
              >
                <Input size='large' prefix={<UserOutlined />} style={{width: 312}} />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true }]}
              >
                <Input size='large' prefix={<LockOutlined />} type='password' style={{width: 312}} />
              </Form.Item>
              <Form.Item>
                <Button size='large' style={{width: 312}} type='primary'  htmlType="submit">登录</Button>
              </Form.Item>
            </Form>
          </div>
          <div className='footerCopyRight' style={{width: '50%'}}>杭州图尔兹信息技术有限公司 ©2023 版权所有浙ICP备18027323号-1</div>
        </div>
      </div>
    </div>
  )
}
export default LoginPage
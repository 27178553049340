/**
 * layout主页面
 * 结构： 左、上、下
 */
import React, { Suspense, useState, useEffect } from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { useSelector } from 'src/hooks'
import { useNavigate } from 'react-router-dom'
import { Spin } from 'antd'
import { LeftMenuChildrenMap } from 'src/utils'
import LeftMenu from './leftMenu'
import RightTopMenu from './rightTopMenu'
import styles from './index.module.scss'

// 概览页面
const OverviewPage = React.lazy(() => import('../overviewPage'))
 
// 数据库帐号管理-实例列表
const DatabaseManageInstanceList = React.lazy(()=>import('../databaseManagePage/instanceListPage'))
// 数据库帐号管理-实例详情-诊断详情
const DatabaseManageInstanceListDiagnosticDetails = React.lazy(()=>import('../databaseManagePage/instanceListPage/DiagnosticDetails'))

// 数据库账号管理-账号列表
const DatabaseManageAccountList = React.lazy(()=>import('../databaseManagePage/accountListPage'))  
// 数据库账号管理-账号列表-详情
const DatabaseManageAccountListDetail = React.lazy(()=>import('../databaseManagePage/accountListPage/Detail'))  
// 数据库账号管理-标签管理
const AccountTagManagePage = React.lazy(()=>import('../databaseManagePage/tagManagePage'))
// 数据库账号管理-参数设置
const ParamsSettingPage = React.lazy(()=>import('../databaseManagePage/paramsSettingPage'))

// 应用服务管理-服务分析
const ApplicationServiceManageAnalysisPage = React.lazy(()=>import('../applicationServiceManagePage/serverAnalysisPage'))
// 应用服务管理-服务分析-详情
const ApplicationServiceManageAnalysisDetail = React.lazy(()=>import('../applicationServiceManagePage/serverAnalysisPage/Detail'))
// 应用服务管理-系统服务
const ApplicationServiceManageSystemServerPage = React.lazy(()=>import('../applicationServiceManagePage/systemServerPage'))
// 应用服务管理-系统服务-数据库账号
const ApplicationServiceManageSystemAccountPage = React.lazy(()=>import('../applicationServiceManagePage/accountListPage'))

// 代理服务管理
const ProxyServiceManagePage = React.lazy(()=>import('../proxyServiceManagePage'))
// 流程审批-我的申请
const ProcessApplyPage = React.lazy(()=>import('../processApprovalPage/myApply'))
// 流程审批-我的审批
const ProcessApprovalPage = React.lazy(()=>import('../processApprovalPage/myApproval'))

// 用户管理-系统用户
const UserManageSystemUser = React.lazy(() => import('../userManagePage/SystemUser'))
// 用户管理-通讯录用户
const UserManageAddressBookUser = React.lazy(() => import('../userManagePage/AddressBookUser'))
// 用户管理-通讯录用户-详情
const UserManageAddressBookUserDetail = React.lazy(() => import('../userManagePage/AddressBookDetail'))

// 审计-操作审计
const AuditOperation = React.lazy(()=>import('../auditPage/OperationPage'))
// 审计-事件信息
const AuditEventInfo = React.lazy(()=>import('../auditPage/EventInfoPage'))

// 系统设置-邮箱\短信设置
const SystemSettingPage = React.lazy(()=>import('../systemSettingPage/index'))


const MainLayoutPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { isLogged } = useSelector((state) => state.login)
  const [tabs, setTabs] = useState<any[]>([])
  const [activeKey, setActiveKey] = useState<string>('')

  useEffect(()=>{
    const routeKey = '/' + location.pathname?.split('/')?.[1]
    const tabContent = LeftMenuChildrenMap[routeKey]
    setTabs(tabContent)
    if(!location.pathname?.includes(activeKey) || !activeKey){
      const defaultActiveKey = tabContent?.[0]?.key
      if(defaultActiveKey){
        setActiveKey(defaultActiveKey)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[location.pathname])

  const updateActiveKey = (activeKey: string) => {
    setActiveKey(activeKey)
    navigate(activeKey)
  }

  const fallbackLoading = (
    <div style={{ cursor: 'wait' }}>
      <Spin spinning style={{ color: '#03204a' }} tip="dpEasy 加载中">
        <div
          style={{
            width: '100vw',
            height: '100vh',
            backgroundColor: '#3f84e9',
          }}
        ></div>
      </Spin>
    </div>
  )

  const AuthRoute  = ({ children }: { children?: JSX.Element }) => {
    const location = useLocation();
    if(isLogged){
      return children ? children : <Navigate to="/workbench" state={{ from: location }} replace />
    }
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return (
    <div className={styles.mainLayoutPage}>
      <div className={styles.left}>
        <LeftMenu />
      </div>
      <div className={styles.right}>
        {
          !!tabs?.length &&
          <RightTopMenu
            tabs={tabs}
            activeKey={activeKey}
            setActiveKey={updateActiveKey}
          />
        }
        <div className="content">
          <Suspense fallback={fallbackLoading}>
            <Routes>
              {/* 工作台 */}
              <Route path='/workbench' element={<AuthRoute><OverviewPage /></AuthRoute>} />
              {/* 数据库账号管理-实力列表 */}
              <Route path="/databaseAccountManagement/instanceList" element={<AuthRoute><DatabaseManageInstanceList /></AuthRoute>} /> 
              {/* 属菊科账号管理-实例详情-诊断详情 */}
              <Route path="/databaseAccountManagement/instanceList/diagnosticDetails" element={<AuthRoute><DatabaseManageInstanceListDiagnosticDetails /></AuthRoute>} />
              {/* 数据库账号管理-账号列表 */}
              <Route path="/databaseAccountManagement/accountList" element={<AuthRoute><DatabaseManageAccountList /></AuthRoute>} />
              {/* 数据库账号管理-账号详情 */}
              <Route path="/databaseAccountManagement/accountList/detail" element={<AuthRoute><DatabaseManageAccountListDetail /></AuthRoute>} />
              {/* 数据库账号管理-标签管理 */}
              <Route path="/databaseAccountManagement/tagManage" element={<AuthRoute><AccountTagManagePage /></AuthRoute>} />
              {/* 数据库账号管理-参数设置 */}
              <Route path="/databaseAccountManagement/paramsSetting" element={<AuthRoute><ParamsSettingPage /></AuthRoute>} />
              {/* 系统服务管理-服务分析 */}
              <Route path="/systemServiceManagement/serverAnalysis" element={<AuthRoute><ApplicationServiceManageAnalysisPage /></AuthRoute>} />
              {/* 系统服务管理-服务分析详情 */}
              <Route path="/systemServiceManagement/serverAnalysis/detail" element={<AuthRoute><ApplicationServiceManageAnalysisDetail /></AuthRoute>} />
              {/* 系统服务管理-系统服务 */}
              <Route path="/systemServiceManagement/systemServer" element={<AuthRoute><ApplicationServiceManageSystemServerPage /></AuthRoute>} />
              {/* 系统服务管理-系统服务--数据库账号 */}
              <Route path="/systemServiceManagement/systemServer/accountList" element={<AuthRoute><ApplicationServiceManageSystemAccountPage /></AuthRoute>} />

              {/* 代理服务管理 */}
              <Route path="/proxyServiceManagement" element={<AuthRoute><ProxyServiceManagePage /></AuthRoute>} />
             
              {/* 流程审批-我的申请 */}
              <Route path="/processApprovalManagement/myApply" element={<AuthRoute><ProcessApplyPage /></AuthRoute>} />
              {/* 流程审批-我的审批 */}
              <Route path="/processApprovalManagement/myApproval" element={<AuthRoute><ProcessApprovalPage /></AuthRoute>} />

              {/* 用户管理 */}
              <Route path="/userManagement/systemUser" element={<AuthRoute><UserManageSystemUser /></AuthRoute>} /> 
              {/* 用户管理-通讯录用户 */}
              <Route path="/userManagement/addressBookUser" element={<AuthRoute><UserManageAddressBookUser /></AuthRoute>} />
              {/* 用户管理-通讯录用户-详情 */}
              <Route path="/userManagement/addressBookUser/detail" element={<AuthRoute><UserManageAddressBookUserDetail /></AuthRoute>} />

              {/* 审计 */}
              <Route path="/auditManagement/operational" element={<AuthRoute><AuditOperation /></AuthRoute>} />  
              <Route path="/auditManagement/eventInfo" element={<AuthRoute><AuditEventInfo /></AuthRoute>} />  

              {/* 系统设置邮箱\短信设置 */}
              <Route path="/systemSetting" element={<AuthRoute><SystemSettingPage /></AuthRoute>} />

              {/* 匹配所有未定义的路由 */}
              <Route path="*" element={<AuthRoute />} />
            </Routes>
          </Suspense>
        </div>
      </div>
    </div>
  )
}
export default MainLayoutPage
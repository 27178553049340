// 策略设置
import { Modal, Form, Button, Divider, InputNumber, Input, Radio, Space, message } from 'antd'
import moment from 'moment';
import { useState } from 'react';
import { updateInstanceAccount } from 'src/apis'
interface IProps {
  open: boolean, 
  setOpen: (v:boolean)=>void
  [p: string]: any
}
const PolicySettingModal = ({open, setOpen, data, refresh}: IProps) => {

  const [form] = Form.useForm();
  const [btnLoading, setBtnLoading] = useState(false)
  
  const handleClose = () => {
    setOpen(false)
  }

  const handleSave = () => {
    form.validateFields().then((values: any)=>{
      setBtnLoading(true)
      const params = {
        id: data?.id,
        ...values
      }
      updateInstanceAccount(params).then((res: any)=>{
        if(res !== undefined){
          message.success('操作成功')
          setOpen(false)
          refresh && refresh()
        }
      }).catch((err: any)=>{
        console.error('更新实例账号失败', err)
      }).finally(()=>{
        setBtnLoading(false)
      })
    }).catch((err: any)=>{
      console.error('form error', err)
    })
  }

  const handleFieldsChange = (changedFields: any) => {
    if(changedFields?.[0]?.name?.[0] === 'pwdValidity'){
      const lastChangePwdTime = form.getFieldValue('lastChangePwdTime') || moment()
      const validDate = changedFields?.[0]?.value
      const nextChangePwdTime = moment(lastChangePwdTime)?.add(validDate, 'days')?.format('YYYY-MM-DD HH:mm:ss')
      form.setFieldsValue({
        nextChangePwdTime: nextChangePwdTime,
        lastChangePwdTime: moment(lastChangePwdTime).format('YYYY-MM-DD HH:mm:ss')
      })
    }
  }

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  return <Modal
    title='策略设置'
    open={open} 
    footer={null}
    onCancel={handleClose}
    >
      <Form
        {...formItemLayout}
        form={form}
        autoComplete="off"
        onFieldsChange={handleFieldsChange}
        initialValues={data}
      > 
        <Form.Item
          label="帐号"
          name="dbUser"
          style={{marginBottom: 12}}
        >
          <Input style={{width: 200}} disabled />
        </Form.Item>
        <Form.Item
          label="连接地址"
          name="dbUrl"
          style={{marginBottom: 12}}
        >
          <Input style={{width: 200}} disabled />
        </Form.Item>
        <Form.Item
          label="改密时间"
          name="lastChangePwdTime"
          style={{marginBottom: 12}}
        >
         <Input style={{width: 200}} disabled />
        </Form.Item>
        <Form.Item
          label="下次改密时间"
          name="nextChangePwdTime"
          style={{marginBottom: 12}}
        >
          <Input style={{width: 200}} disabled />
        </Form.Item>
        <div style={{color:'#666'}}>改密策略<Divider style={{margin: ' 4px 0 12px 0'}} /></div>
        {/* <Form.Item
          label=''
          name="changeMode"
          labelCol={{ span: 0 }}
          wrapperCol={{ span: 16, offset: 8 }} 
        >
          <Radio.Group>
            <Space direction="vertical">
              <Radio value={'manual'}>手动修改</Radio>
              <Radio value={'auto'}>自动修改</Radio>
            </Space>
          </Radio.Group>
        </Form.Item> */}
        <Form.Item
          label="密码有效期（天）"
          name="pwdValidity"
          tooltip="手动修改模式下，该参数仅做提示"
          rules={[{required: true}]}
        >
         <InputNumber precision={0} min={0} />
        </Form.Item>
      </Form>
      <Divider />
      <Button loading={btnLoading} type='primary' className='flexEnd' onClick={handleSave}>保存</Button>
    </Modal>
}

export default PolicySettingModal
import { message, Typography, Button } from 'antd'
import { setIsLogged, setUserInfo } from 'src/pages/loginPage/loginPageSlice'
import { useDispatch } from 'src/hooks'

export const LogoutMessage = () => {
  const dispatch = useDispatch()
  return (
    <Typography.Text>
      用户未登录或登录失效，
      <Button
        type="link"
        size="small"
        onClick={() => {
          message.destroy()
          dispatch(setIsLogged(false))
          dispatch(setUserInfo({}))
        }}
      >
        请立即登录！
      </Button>
    </Typography.Text>
  )
}
import { fetchGet }  from './customFetch'

// 账号数量统计 
export const getStatisticSummary = () => {
  return fetchGet('/manager/instance/statistic/summary')
}

// 饼图数量    tabType: INSTANCE/AUTH/MACHINE/HUMAN
export const getStatisticPieChart = (tabType: string) => {
  return fetchGet(`/manager/instance/statistic/pieChart/${tabType}`)
}

// 系统信息统计
export const getStatisticLineChart = () => {
  return fetchGet('/manager/system/statistic/lineChart')
}

// 风险账号统计
export const getRiskViewStatistic = (datasourceType: string) => {
  return fetchGet(`/manager/risk-view/statistic/${datasourceType}`)
}

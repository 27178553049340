/**
 * SM2 引入使用方法
 */
const sm2 = require('sm-crypto').sm2

export interface IEncryptKey {
    password: string
    publicKey: string
}

export const setEncrypt = (params: IEncryptKey) => {
    let passwordMsg: string | boolean
    const key = atob(params.publicKey)
    const encData = sm2.doEncrypt(params.password, key, 1 )
    passwordMsg = btoa(unescape(encodeURIComponent(encData)))
    return passwordMsg
}
import { fetchDelete, fetchPost, fetchPut, fetchPostFormData }  from './customFetch'
import { getTransmissionPublicKey } from './login'
import { setEncrypt } from 'src/utils/encryptRsaSm2'
interface ListParamsI {
  pageNumber: number
  pageSize: number
  roleType?: string
  [p: string]: any
}
// 用户列表
export const getUsersList = (params: ListParamsI) => {
  return fetchPost(`/manager/user/page`, params)
}

// 添加用户
export const addUser = async(params: any) => {
  if(params?.password){
    const getKey = await getTransmissionPublicKey()
    params.password  = setEncrypt({ publicKey: getKey, password: params.password})
  }
  return fetchPut(`/manager/user`, params)
}

// 删除用户
export const deleteUser= (id: any) => {
  return fetchDelete(`/manager/user/${id}`)
}

// 修改用户
export const updateUser = (params: any) => {
  return fetchPost(`/manager/user`, params)
}

// 批量导入通讯录
export const batchImportUser = (params: any) => {
  return fetchPostFormData(`/manager/user/import`, params)
}
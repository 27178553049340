/**
 * 密码授权
 */
import { useEffect, useState } from 'react'
import { renderTableFields } from 'src/utils'
import { Modal, Form, Input, Button, Divider, Badge, message, Spin, InputNumber } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { Iconfont, CustomTable } from '@components'
import { getUsersList, passwordAuthorization } from 'src/apis'
import { useRequest } from 'ahooks' 
import moment from 'moment'

interface IProps {
  open: boolean
  setOpen:(b: boolean)=>void
  accountInfo?: any
  [p: string]: any
}
const PasswordAuthorizeModal = ({open, setOpen, accountInfo, refresh}:IProps) => {
  const [form] = Form.useForm();

  const [pagination, setPagination] = useState({
    pageSize: 10,
    current: 1,
    total: 0
  })
  const [dataSource, setDataSource] = useState([])
  const [selectKeys, setSelectKeys] = useState<any[]>([])
  const [btnLoading, setBtnLoading] = useState(false)

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectKeys(selectedRowKeys)
      form.setFieldsValue({
        ...selectedRows?.[0],
        ...accountInfo
      })
    }
  }

  const { loading, run: queryData } = useRequest(getUsersList, {
    manual: true,
    onSuccess:(res: any, paraments: any)=>{
      const { records=[], total=0 } = res
      const pagination = {
        pageSize: paraments[0]?.pageSize,
        current: paraments[0]?.pageNumber,
        total
      }
      setPagination(pagination)
      setDataSource(records)
    }
  })

  useEffect(()=>{
    const params = {
      pageNumber: 1,
      pageSize: 10,
    }
    queryData(params)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

    // table change
    const handleTableChange = (
      pagination: any,
      filters: Record<string, any>,
    ) => {
      const { pageSize = 1000, current = 1 } = pagination
      const params ={
        pageSize,
        pageNumber: current
      }
      queryData(params)
    }

  const handleSave = () => {
    if(!selectKeys?.length){
      return message.error('请选择账号')
    }
    form.validateFields().then((values: any)=>{
      setBtnLoading(true)
      const {note, validity} = values
      const params = {
        userId: selectKeys?.join(','),
        dbUser: accountInfo?.dbUser,
        instanceAccountId: accountInfo?.id,
        instanceId: accountInfo?.instanceId,
        note,
        validity
      }
      passwordAuthorization(params).then((res: any)=>{
        if(res !== undefined){
          message.success('操作成功')
          setOpen(false)
          refresh && refresh()
        }
      }).catch((err: any)=>{
        console.error('密码授权失败:', err)
      }).finally(()=>{
        setBtnLoading(false)
      })

    }).catch((err: any)=>{
      console.error('form error', err)
    })
  }

  const columns: any = [{
    title: '姓名',
    dataIndex:'userName',
    width: 100,
		ellipsis: true,
    render:(txt: string)=><span>{renderTableFields(txt)}</span>
  },{
    title: '邮箱',
    dataIndex:'email',
    width: 100,
		ellipsis: true,
    render:(txt: string)=><span>{renderTableFields(txt)}</span>
  },
  {
    title:'联系方式',
    dataIndex:'phone',
    width: 120,
		ellipsis: true,
    render:(txt: string)=><span>{renderTableFields(txt)}</span>
  }]

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const handleFieldValueChange = (current: any) => {
    if(current?.validity !== undefined){
      const value = moment().add(current?.validity, 'days').format('YYYY-MM-DD')
      form.setFieldValue('dueDate', value)
    }
  }

  return (
    <Modal 
      width={1000}
      centered 
      title='密码授权' 
      open={open} 
      footer={null}
      onCancel={()=>setOpen(false)}
    >
      <Spin spinning={loading}>
        <div className='flex'>
          <div style={{width:'60%', paddingRight: 20, borderRight: '1px solid #e5e5e5'}}>
            <Input 
              placeholder='搜索用户名' 
              prefix={<SearchOutlined />} 
              allowClear
              style={{marginBottom: '10px'}}
            />
            <CustomTable 
              bordered
              columns={columns}
              dataSource={dataSource}
              pagination={pagination}
              rowKey={'id'}
              rowSelection={{
                type: 'radio',
                ...rowSelection,
              }}
              scroll={{y: 'calc(100vh - 400px)'}}
              onChange={handleTableChange}
            />
          </div>
          <div style={{width: '40%', paddingLeft: 20}}>
            <Form
              {...formItemLayout}
              form={form}
              autoComplete="off"
              initialValues={accountInfo}
              onValuesChange={handleFieldValueChange}
            > 
              <Form.Item
                label={<span><Badge className='mr4' status={accountInfo?.initialized ? "success" : "error"} />账号</span>}
                name="dbUser"
                style={{marginBottom: 4}}
              >
                <Input style={{width: 200}} disabled />
              </Form.Item>
              <Form.Item
                label='状态'
                name="status"
                style={{marginBottom: 4}}
              >
                <Input style={{width: 200}} disabled />
              </Form.Item>
              <Form.Item
                label='连接地址'
                name="dbUrl"
                style={{marginBottom: 4}}
              >
                <Input 
                  prefix={<Iconfont type={`icon-${accountInfo?.datasourceType}`} />}
                  style={{width: 200}} 
                  disabled 
                />
              </Form.Item>
              <Form.Item
                label='改密时间'
                name="lastChangePwdTime"
                style={{marginBottom: 4}}
              >
                <Input style={{width: 200}} disabled />
              </Form.Item>
              <Form.Item
                label='下次改密时间'
                name="nextChangePwdTime"
                style={{marginBottom: 4}}
              >
                <Input style={{width: 200}} disabled />
              </Form.Item>
              <Divider style={{margin: '10px 0'}} />
              <Form.Item
                label='被授权人'
                name="userName"
                style={{marginBottom: 4}}
              >
                <Input style={{width: 200}} disabled />
              </Form.Item>
              <Form.Item
                label='邮箱'
                name="email"
                style={{marginBottom: 4}}
              >
                <Input style={{width: 200}} disabled />
              </Form.Item>
              <Form.Item
                label='联系电话'
                name="phone"
                style={{marginBottom: 4}}
              >
                <Input style={{width: 200}} disabled />
              </Form.Item>
              <Form.Item
                label='备注'
                name="note"
                style={{marginBottom: 4}}
              >
                <Input.TextArea style={{width:200}} rows={3} />
              </Form.Item>
              <Form.Item
                label='有效期（天）'
                name="validity"
                tooltip="账号到期自动锁定， 如账号涉及应用服务则到期不做回收"
                style={{marginBottom: 4}}
                rules={[{required: true}]}
              >
                <InputNumber style={{width: 200}} precision={0} min={1} />
              </Form.Item>
              <Form.Item
                label='到期日期'
                name="dueDate"
                style={{marginBottom: 4}}
              >
                <Input style={{width: 200}} disabled />
              </Form.Item>
            </Form>
          </div>
        </div>
        <Divider style={{margin: '20px 0'}} />
        <Button 
          loading={btnLoading} 
          className='flexEnd' 
          type='primary' 
          onClick={handleSave}
        >
          {accountInfo?.lock ? '解锁并重置密码':'授权'}
        </Button>
      </Spin>
    </Modal>
  ) 
}
export default PasswordAuthorizeModal
/**
 * 左侧菜单
 */
import React, { useEffect, useState } from 'react'
import { Menu, Modal, Dropdown, Form, Input, message } from 'antd'
import type { MenuProps } from 'antd'
import { useLocation, Link } from 'react-router-dom'
import { Iconfont } from 'src/components'
import { useNavigate} from 'react-router-dom'
import { useSelector, useDispatch } from 'src/hooks'
import { loginOut, changePassword }  from 'src/apis'
import { setIsLogged, setUserInfo } from 'src/pages/loginPage/loginPageSlice'

import { LeftMenuKeys } from 'src/utils'
import logo from 'src/assets/img/img_logo.svg'
import logoAndTxt from 'src/assets/img/img_txt_logo.svg'
import styles from './index.module.scss'
type MenuItem = Required<MenuProps>['items'][number];

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const LeftMenuPage = () => {
  const location = useLocation();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [selectedKeys, setSelectedKeys] = useState<string[]>(['/workbench']) 
  const { userInfo } = useSelector((state) => state.login)

  const avaterContent = userInfo?.userName?.toString()?.charAt(0) // 用户名第一个字（暂时没有用户头像）
  const showDatabaseProxy = userInfo?.appearance?.DATABASE_PROXY_ENABLE

  useEffect(()=>{
    for(let key of LeftMenuKeys){
      if(location.pathname?.startsWith(key)){
        setSelectedKeys([key])
        break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[location.pathname])

  const items:MenuProps['items'] = [
    {
      label: <a download href={window.location.origin + '/drivers/dpeasy-jdbc-1.0-SNAPSHOT.jar'} target='_blank' rel="noreferrer">驱动下载</a>, // '192.168.3.50:80/drivers/dpeasy-jdbc-1.0-SNAPSHOT.jar'
      key: '1',
    },
    {
      label: "修改密码",
      key: '2',
    },
    {
      label: "退出登录",
      key: '3',
    },
  ]

  const handleMenuClick = (data: any) => {
    const { key} = data
    switch(key){
      case '2':
        Modal.confirm({
          title:' 修改密码',
          content: (
            <Form
              {...formItemLayout}
              form={form}
              autoComplete="off"
              className='mt20'
            > 
              <Form.Item
                label="密码"
                name="password"
                rules={[{ 
                  required: true,
                  validator(_, value) {
                    const passwordRegex = /^(?=.*\d)(?=.*)(?=.*[A-Z]).{8,16}$/;
                    if(!value){
                      return Promise.reject(new Error('请输入密码！'))
                    }
                    if (passwordRegex.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('密码长度为8-16位，必须包含大小写英文字母和数字!'));
                  }, 
                }]} 
              >
                <Input type='password' style={{width: 160}} />
              </Form.Item>
              <Form.Item
                label="确认密码"
                name="rePassword"
                rules={[
                  {
                    required: true,
                    message: '请再次确认密码!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('两次密码不一致!'));
                    },
                  }),
                ]}
              >
                <Input type='password' style={{width: 160}} />
              </Form.Item>
            </Form>
          ),
          onOk:() => {
            form.validateFields().then(values=>{
              const params = {
                id: userInfo?.id,
                password: values?.password
              }
              changePassword(params).then((res: any)=>{
                if(res !== undefined){
                  message.success('修改成功')
                }
              }).catch(err=>{
                console.error('修改密码失败:', err)
              }).finally(()=>{
                form.resetFields()
              })
            }).catch(err=>{
              console.error('form error:', err)
            })
          },
          onCancel:()=>{
            form.resetFields()
          }
        })
        break
      case '3':
        // 退出登录
        loginOut().then(()=>{
          dispatch(setIsLogged(false))
          dispatch(setUserInfo({}))
          navigate('/login')
        }).catch(err=>{console.error('退出失败:', err)})
        break
    }
  }

  const getItem = (
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],

  ): MenuItem =>{
    return {
      key,
      icon,
      children,
      label,
    } as MenuItem;
  }

  const leftMenuItems: MenuProps['items'] = [
    getItem(<Link to="/workbench">概览</Link>, '/workbench', <Iconfont style={{fontSize: '20px'}} type='icon-gongzuotai' />),
    getItem(<Link to="/databaseAccountManagement/instanceList">账号</Link>, '/databaseAccountManagement', <Iconfont style={{fontSize: '20px'}} type='icon-shujukuzhanghaoguanli1' />),
    getItem(<Link to="/systemServiceManagement/serverAnalysis">应用</Link>, '/systemServiceManagement', <Iconfont style={{fontSize: '20px'}} type='icon-xitongfuwuguanli1' />),
    getItem(<Link to="/processApprovalManagement/myApply">审批</Link>, '/processApprovalManagement', <Iconfont style={{fontSize: '20px'}} type='icon-liuchengshenpi1' />),
    getItem(<Link to="/userManagement/systemUser">用户</Link>, '/userManagement', <Iconfont style={{fontSize: '20px'}} type='icon-yonghuguanli1' />),
    getItem(<Link to="/auditManagement/operational">审计</Link>, '/auditManagement', <Iconfont style={{fontSize: '20px'}} type='icon-shenji1' />),
    getItem(<span />, 'divider'),
    getItem(<Link to="/systemSetting">设置</Link>, '/systemSetting', <Iconfont style={{fontSize: '20px'}} type='icon-xitongshezhi1' />),
    getItem('', '/notice', <Iconfont style={{fontSize: '20px'}} type='icon-tongzhi' />),
    getItem('', '', 
      <Dropdown menu={{ items, onClick: handleMenuClick }}>
        <span 
          style={{
            width:32, 
            height:32, 
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            borderRadius: 32, 
            color:'#fff',
            backgroundColor:'#1677ff'
          }}
        >
          {avaterContent}
        </span>
      </Dropdown>
    ),
  ];

  // 动态添加代理服务菜单
  if(showDatabaseProxy){
    leftMenuItems.splice(3, 0, getItem(<Link to="/proxyServiceManagement">代理</Link>, '/proxyServiceManagement', <Iconfont style={{fontSize: '20px'}} type='icon-dailifuwuguanli1' />))
  }

  return (
    <div className={styles.leftMenuWrap}>
      <div className={styles.leftMenu}>
        <div className={styles.logo}>
          <img alt='logo' src={logo} />
        </div>
        <Menu
          mode="inline"
          items={leftMenuItems}
          selectedKeys={selectedKeys}
          className={styles.customMenuStyle} 
        />
      </div>
    </div>
  )
}
export default LeftMenuPage
// 文本切换是否可见、复制功能
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import { message } from 'antd'
import { useState } from 'react'

interface IProps{
  text: string
  [p: string]: any
}
export const TextCanVisibleAndCopy = (props: IProps) => {
  const {showCopy=true, showVisible=true, text} = props

  const [visible, setVisible] = useState<boolean>(false)

  const toggleVisible = () => {
    setVisible(v=>!v)
  }

  const handleCopy = async() => {
   await navigator.clipboard.writeText(text);
   message.success('复制成功')
  }
  return (
    <div className='flexAlignCenter'>
      <span className='textOverflow mr10' title={text} style={{maxWidth:'400px'}}>{visible ? text : '********'}</span>
      {
        showVisible && (
          <span className='mr10 pd4 options' onClick={toggleVisible}>
            {
              visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
            }
          </span>
        )  
      }
      {showCopy && <span className='options' onClick={handleCopy}>复制</span>}
    </div>
  )
}

import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { rootReducer } from './reducers'
import { storeRegister } from './storeRegister'

const persistConfig = {
  key: 'root',
  storage,
  // whitelist: ['userPage'] // userPage will be persisted （default all）
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export const persistor = persistStore(store)

storeRegister.register(store, persistor)

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;








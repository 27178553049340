/**
 * 账号托管
 */
import { useState } from 'react'
import { Modal, Form, Input, Button, message, Badge, Spin, Select } from 'antd'
import { instancePasswordInit, passwordTestConnection } from 'src/apis'

interface IProps {
  open: boolean
  setOpen:(b: boolean)=>void
  [p: string]: any
}
const InitPasswordSettingModal = ({open, setOpen, data}:IProps) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [testStatus, setTestStatus] = useState<number>(0)

  const handleSave = () => {
    form.validateFields().then((values: any)=>{
      setBtnLoading(true)
      const params = {
        id: data?.id, 
        dbPassword: values?.password,
        connectRole: values?.connectRole
      }
      instancePasswordInit(params).then((res: any)=>{
        if(res !== undefined){
          message.success('操作成功')
          setOpen(false)
        }
      }).catch((err: any)=>{
        console.error('密码授权失败:', err)
      }).finally(()=>{
        setBtnLoading(false)
      })

    }).catch((err: any)=>{
      console.error('form error', err)
    })
  }

  // test connection
  const handleTestConnection = () => {
    form.validateFields().then((values: any)=>{
      const params = {
        id: data?.id, 
        dbPassword: values?.password,
        connectRole: values?.connectRole
      }
      setLoading(true)
      passwordTestConnection(params).then((res: any)=>{
        if(res !== undefined){
          message.success('测试成功')
          setTestStatus(1)
        }else{
          setTestStatus(2)
        }
      }).catch((err: any)=>{
        setTestStatus(2)
        console.error('测试连接失败:', err)
      }).finally(()=>{
        setLoading(false)
      })

    }).catch((err: any)=>{
      console.error('form error', err)
    })
  }

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  return (
    <Modal 
      centered 
      title='账号托管' 
      open={open} 
      footer={null}
      onCancel={()=>setOpen(false)}
    >
      <Spin spinning={loading}>
        <Form
          {...formItemLayout}
          form={form}
          autoComplete="off"
        > 
          <Form.Item
            label='密码'
            name="password"
            rules={[{required: true}]}
          >
            <Input style={{width: 200}} type='password' />
          </Form.Item>
          <Form.Item
            label='再次确认'
            name="repeatPassword"
            rules={[
              {
                required: true,
                message: '请再次确认密码!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('两次密码不一致!'));
                },
              }),
            ]}
          >
            <Input style={{width: 200}} type='password' />
          </Form.Item>
          {
            data?.datasourceType?.toLowerCase() === 'oracle' &&
            <Form.Item
              label='角色'
              name="connectRole"
              rules={[{required: true}]}
            >
              <Select 
                style={{width: 200}} 
                placeholder='请选择' 
                options={[
                  {
                    label:'SYSDBA',
                    value:'SYSDBA'
                  },
                  {
                    label:'SYSOPER',
                    value:'SYSOPER'
                  },
                  {
                    label:'NORMAL',
                    value:'NORMAL'
                  }
                ]}
              />
            </Form.Item>
          }
        </Form>
        <div className='mtb20 options' onClick={handleTestConnection}>
          测试连接
          <Badge 
            className='ml10' 
            color={testStatus===1 ? 'green' : testStatus===0 ? 'default' : 'red'} 
            text={testStatus===1 ? '成功' : testStatus===0 ? '': '失败'} />
        </div>
        <div className='flexEnd'>
          <Button className='mr10' onClick={()=>setOpen(false)}>取消</Button>
          <Button type='primary' loading={btnLoading} onClick={handleSave}>确定</Button>
        </div>
      </Spin>
    </Modal>
  ) 
}
export default InitPasswordSettingModal
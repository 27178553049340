// 代理服务管理
import { fetchGet, fetchPost }  from './customFetch'

// 代理信息列表
export const getProxyList = (params?: any) => {
  return fetchGet(`/manager/proxy/detail`, params) 
}

// 新建数据库代理
export const createDataBaseProxy = (params: any) => {
  return fetchPost(`/manager/proxy`, params) 
}

// 代理关联
export const associateProxy = (params: any, proxyId: string) =>{
  return fetchPost(`/manager/proxy/${proxyId}`, params) 
}
  
/**
 * 账号Table操作项汇总
 */
import { useState } from 'react'
import { useNavigate } from "react-router-dom"
import { Dropdown, Modal, message } from 'antd'
import { updateInstanceAccount, lockToggleAccount, testManageAccountConnection } from 'src/apis'
import PolicySettingModal from './modal/PolicySettingModal'
import ApplyModifyPassword from './modal/ApplyModifyPassword'
import PasswordAuthorizeModal from './modal/PasswordAuthorizeModal'
import InitPasswordSettingModal from './modal/InitPasswordSettingModal'

const defaultItems = (record: any) => [
  {
    key:'1',
    label:<span className={record?.isSysBuilt ? 'disabled': ''}>策略设置</span>
  },
  {
    key:'2',
    label:<span className={record?.isSysBuilt ? 'disabled': ''}>申请改密</span>
  },
  {
    key:'4',
    label: <span className={record?.isSysBuilt ? 'disabled': ''}>{record?.status==='OPEN' ? '账号锁定' : '账号解锁'}</span>
  },
  {
    key:'5',
    label:<span className={record?.isSysBuilt ? 'disabled': ''}>密码授权</span>
  },
  {
    key:'6',
    label:<span className={record?.initialized || record?.isSysBuilt ? 'disabled': ''}>账号托管</span>
  },
  {
    key:'7',
    label:<span>测试连接</span>
  }
];

interface IProps {
  record: any,
  [p: string]: any
}
export const AccountOptions = ({ record, refresh, items=defaultItems}: IProps) => {
  const navigate = useNavigate()
  const [modifyPasswordModalVisible, setModifyPasswordModalVisible] = useState(false)
  const [policySettingModalVisible, setPolicySettingModalVisible] = useState(false)
  const [passwordAuthorizeModalVisible, setPasswordAuthorizeModalVisible] = useState(false)
  const [initPasswordSettingModalVisible, setInitPasswordSettingModalVisible] = useState(false)
  const [modifyData, setModifyData] = useState<any>({})
  
  const handleRefresh = () => {
    refresh && refresh()
  }

  // 策略设置
  const handlePolicySetting = (record: any) => {
    setModifyData(record)
    setPolicySettingModalVisible(true)
  }

  // 修改密码
  const handleModifyPassword = (record: any) => {
    setModifyPasswordModalVisible(true)
    setModifyData(record)
  }
 
  // 账号锁定解锁
  const handleLockToggle = (rescord: any) => {
    const txt = record?.status==='OPEN' ? '锁定' : '解锁'
    Modal.confirm({
      title:`账号${txt}`,
      content:`确定要${txt}该账号吗?`,
      onOk:()=>{
        const params = {
          accountId: record?.id,
          lock: record?.status==='OPEN' ? true : false
        }
        lockToggleAccount(params).then((res: any)=>{
          if(res !== undefined){
            message.success('操作成功')
            handleRefresh()
          }
        }).catch((err: any)=>{
          console.error(`${txt}失败:`, err)
        })
      }
    })
  }

  // 密码授权
  const handlePasswordAuthorize = (record: any) => {
    const { isSysBuilt, authorized, usage, authorizedUserName, authorizedUser, servicesRelation } = record
    if(isSysBuilt){
      return
    }
    const authorizedFlag1 = (servicesRelation || usage === 'MACHINE_ACCOUNT' ) && !authorized    // 改造完成 或 没有改造，无被授权人对外授权
    const authorizedFlag2 = (servicesRelation || usage === 'MACHINE_ACCOUNT') && authorized   // 不备注了，看不懂找雨伦或其他后端
    const authorizedFlag3 = !servicesRelation && usage !== 'MACHINE_ACCOUNT' && !authorized
    const authorizedFlag4 = !servicesRelation && usage !== 'MACHINE_ACCOUNT' && authorized

    Modal.confirm({
      title:'提示',
      content: <span>
        {
          authorizedFlag1 &&
          <span>当前账号涉及应用系统使用，不支持有效期内回收账号，请谨慎授权。</span>
        }
        {
          authorizedFlag2 &&
          <span>
            当前账号涉及应用系统使用，不支持有效期内回收账号，请谨慎授权。
            <span><br />当前账号已授权给{authorizedUserName}({authorizedUser})，此次授权操作拒绝</span>
          </span>
        }
        {
          authorizedFlag3 && <span>此次授权将解锁账号并重置密码</span>
        } 
        {
          authorizedFlag4 && <span>当前账号已授权给{authorizedUserName}({authorizedUser})，此次授权将重置密码，并导致{authorizedUserName}({authorizedUser})无法登录</span>
        }     
      </span>,
      onOk:()=>{
        setPasswordAuthorizeModalVisible(true) 
        setModifyData(record)
      },
      okButtonProps:{style:{display: authorizedFlag2 ? 'none' : 'inline-block'}}
    })
  }

  // 账号托管
  const handleInitPassword = (record: any) => {
    setInitPasswordSettingModalVisible(true)
    setModifyData(record)
  }

  // 测试连接
  const handleTestConnection = (record: any) => {
    testManageAccountConnection(record?.id).then((res: any)=>{
      if(res !== undefined){
        message.success('连接成功')
      }
    }).catch((err: any)=>{
      console.error(`测试连接失败:`, err)
    })
  }
  
  // 查看
  const handleLook = (record: any) => {
    navigate(`/databaseAccountManagement/accountList/detail?id=${record?.id}&instanceId=${record?.instanceId}`)
    localStorage.setItem('instanceAccountItem', JSON.stringify(record))
  }

  const onMenuClick = (e: any, record: any) => {
    switch(e.key){
      case '1':
        // 策略设置
        if(record?.isSysBuilt){
          return
        }
        handlePolicySetting(record)
        break
      case '2':
        // 申请改密
        if(record?.isSysBuilt){
          return
        }
        handleModifyPassword(record)
        break
      case '4':
        // 账号锁定/解锁
        if(record?.isSysBuilt){
          return
        }
        handleLockToggle(record)
        break
      case '5':
        // 密码授权
        handlePasswordAuthorize(record)
        break
      case '6':
        // 账号托管
        if(record?.initialized || record?.isSysBuilt) {
          return
        }
        handleInitPassword(record)
        break
      case '7':
        // 测试连接
        handleTestConnection(record)
    }
  };

  return (
    <>
      <Dropdown.Button 
        type='primary' 
        onClick={()=>handleLook(record)} 
        menu={{ items: items(record), onClick: (e)=>onMenuClick(e, record) }}
      >
        查看
      </Dropdown.Button>

      {/* 策略设置 */}
      {
        policySettingModalVisible &&
        <PolicySettingModal 
          open={true}
          setOpen={setPolicySettingModalVisible}
          data={modifyData}
          refresh={handleRefresh}
        />
      }
      {/* 申请改密 */}
      {
        modifyPasswordModalVisible && 
        <ApplyModifyPassword 
          open={true}
          setOpen={setModifyPasswordModalVisible}
          data={modifyData}
        />
      }
      {/* 密码授权 */}
      {
        passwordAuthorizeModalVisible &&
        <PasswordAuthorizeModal
          open={true}
          setOpen={setPasswordAuthorizeModalVisible}
          accountInfo={modifyData}
          refresh={handleRefresh}
        />
      }
      {/* 账号托管 */}
      {
        initPasswordSettingModalVisible &&
        <InitPasswordSettingModal 
          open={true}
          setOpen={setInitPasswordSettingModalVisible}
          data={modifyData}
        />
      }
    </>
  )
}
export default AccountOptions
import { message } from 'antd'
import qs from 'qs'
import { LogoutMessage } from 'src/components'
import { ProviderStore } from 'src/index'

let originalRequestRetry: any = {}
const handleFetchData = (url: string, config?: any, retry?: boolean) => {
  const accessToken = localStorage.getItem('accessToken')
  const options = { 
    method: 'GET',
    headers: {},
    ...config, 
  }
  options.headers['Authorization'] = accessToken

  return fetch( url, options)
  .then(async response => {
    const result = await response?.json()
    if(!response.ok){
      return message.error(`${response.status}, ${response.statusText}`)
    }
    if(result?.resCode===0){
     return message.error(`${result?.resMsg}`)
    }
    originalRequestRetry.url = false
    if(result?.resCode===401){
      const refreshToken = localStorage.getItem('refreshToken')
      // 重试不需要刷新token
      if(!originalRequestRetry.url && refreshToken && !retry){
        originalRequestRetry.url = true
        // get refreshToken
        return fetch('/manager/user/refresh',  {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({ refreshToken }) 
        }).then(async response => {
          const res = await response?.json()
          if(res?.resCode === 401){
            return message.warning(<ProviderStore><LogoutMessage /></ProviderStore>)
          }
          const { accessToken, refreshToken } = res?.data || {}
          localStorage.setItem('accessToken', accessToken)
          localStorage.setItem('refreshToken', refreshToken)
          originalRequestRetry.url = false
          // retry request
          return handleFetchData(url, options, true)
        }).catch((err: any)=>{console.log('刷新token失败', err)})
       
      }else{
        return message.warning(<ProviderStore><LogoutMessage /></ProviderStore>)
      }
    }
    return result
  })
  .then(result => {
    const { data } = result
    const { accessToken, refreshToken } = data || {}
    if(accessToken && refreshToken){
      localStorage.setItem('accessToken', accessToken)
      localStorage.setItem('refreshToken', refreshToken)
    }
    // 重试在内部调用结构有变化
    if(retry){
      return result
    }
    return result?.data 
  })
  .catch(error => {
    console.error('Error:', error);
  });
}

export const fetchGet = (url: string, params?: any) => {
  if(params){
    url += "?" + qs.stringify(params);
  }
  return handleFetchData(url)
} 

export const fetchDelete = (url: string, params?: any) => {
  if(params){
    url += "?" + qs.stringify(params);
  }
  return handleFetchData(url, {
    method: 'DELETE',
  })
} 

export const fetchPost = (url: string, params={}) => {
  return handleFetchData(url, { 
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(params) 
  })
}

export const fetchPut = (url: string, params={}) => {
  return handleFetchData(url, { 
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(params) 
  })
}

export const fetchPostFormData = (url: string, params={}) => {
  return handleFetchData(url, { 
    method: 'POST',
    body: params,
  })
}
import { ConfigProvider } from 'antd'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import MainLayoutPage from './pages/mainLayoutPage'
import LoginPage from 'src/pages/loginPage'
import zhCN from 'antd/locale/zh_CN'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import './App.css';
import './styles/common.scss'
import './styles/overwrite.scss'

dayjs.locale('zh-cn')

const App = () => {
  return (
    <ConfigProvider locale={zhCN}>
      <BrowserRouter>
        <Routes>
          <Route path="login" element={<LoginPage />} />
          <Route path="*" element={<MainLayoutPage />} />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  )
}
  
export default App
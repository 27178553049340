import { fetchPost, fetchGet }  from './customFetch'
import { setEncrypt } from 'src/utils/encryptRsaSm2'

//  登录
interface LoginParamsI {
  username: string
  password: string
}
export const login = async(params: LoginParamsI) => {
  const getKey = await getTransmissionPublicKey()
  params.password  = setEncrypt({ publicKey: getKey, password: params.password})
  return fetchPost(`/manager/user/login`, params)
}

// 登出
export const loginOut = () => {
  return fetchPost('/manager/user/logout')
}

/** 加密算法 获取公钥 RSA|SM2 */
export const getTransmissionPublicKey = () => {
  return fetchGet('/manager/password/publicKey')
}

// 修改密码
export const changePassword = async(params: any) => {
  const getKey = await getTransmissionPublicKey()
  params.password  = setEncrypt({ publicKey: getKey, password: params.password})
  return fetchPost('/manager/user/reset', params)
}

// 刷新token值
export const getRefreshToken = (params: any) => {
  return fetchPost('/manager/user/refresh', params)
}

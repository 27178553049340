// 数据库管理
import { fetchGet, fetchPost, fetchPut, fetchDelete, fetchPostFormData }  from './customFetch'
import { getTransmissionPublicKey } from './login'
import { setEncrypt } from 'src/utils/encryptRsaSm2'
interface ListParamsI {
  pageNumber: number
  pageSize: number
  [p: string]: any
}
// 数据库服务信息
export const databaseServerList = (params: ListParamsI) => {
  return fetchPost(`/manager/database/page`, params) 
}

// 应用库帐号信息
export const applicationAccountList = (params: ListParamsI) => {
  return fetchPost(`/manager/account/page`, params) 
}

// 查询系统服务账号信息
export const systemServiceAccountList = (params: ListParamsI)  => {
  return fetchPost(`/manager/system/accounts`, params)
}

// 系统服务列表
export const systemServiceList = (params: ListParamsI)  => {
  return fetchPost(`/manager/system/services`, params)
}

// 系统服务联系人列表
export const systemServiceConcats = (params: ListParamsI)  => {
  return fetchPost(`/manager/system/contacts`, params)
}

// 添加删除系统服务联系人
export const AddSystemServiceConcats = (params: any) => {
  return fetchPost(`/manager/system/contacts/true`, params)
}

// 添加删除系统服务联系人
export const DeleteSystemServiceConcats = (params: any) => {
  return fetchPost(`/manager/system/contacts/false`, params)
}

// 生成密码
export const generatePassword = () => {
  return fetchGet(`/manager/password/generate`) 
}

// 添加帐号 
export const addAccount = (params: any) => {
  return fetchPut(`/manager/account`, params) 
}

// 修改帐号 
export const modifyAccount = (params: any) => {
  return fetchPost(`/manager/account`, params) 
}

// 删除帐号 
export const deleteAccount = (id: any) => {
  return fetchDelete(`/manager/account/${id}`) 
}

// 实例账号列表查询
export const queryInstanceAccountList = (params: any) => {
  return fetchPost(`/manager/instance/account/page`, params)
}

// 更新实例账号
export const updateInstanceAccount = (params: any) => {
  return fetchPost(`/manager/instance/account`, params)
}

// 锁定或解锁账号
interface LockAccountParams {
  accountId: number|string, 
  lock: boolean
  [p: string]: any
}
export const lockToggleAccount = ({accountId, lock}: LockAccountParams) => {
  return fetchPost(`/manager/instance/account/${accountId}/${lock}`)
}

// 服务分析数量统计
export const queryServiceCount = () => {
  return fetchGet(`/manager/service/count`)
}

// 会话列表
export const querySessionList = (params: any) => {
  return fetchPost(`/manager/session/page`, params)
}

// 应用下会话分析
export const querySessionAnalysisAll = (params: any) => {
  return fetchPost(`/manager/session/analysisAll`, params)
}

// 实例下会话分析
export const querySessionAnalysis = (params: any) => {
  return fetchPost(`/manager/session/analysis`, params)
}

// 实例列表查询
export const queryInstanceList = (params: any) => {
  return fetchPost(`/manager/instance/page`, params)
}

// 根据id查询实例
export const queryInstanceById = (id: any) => {
  return fetchGet(`/manager/instance/${id}`)
}

// 根据id查询实例账号
export const queryInstanceAccountById = (id: any) => {
  return fetchGet(`/manager/instance/account/${id}`)
}

// 实例列表数据导入
export const importInstanceList = (params: any) => {
  return fetchPostFormData(`/manager/instance/import`, params)
}

// 新增实例
export const addInstance = (params: any) => {
  return fetchPut(`/manager/instance`, params)
}

// 更新实例
export const modifyInstance = (params: any) => {
  return fetchPost(`/manager/instance`, params)
}

// 删除实例
export const deleteInstance = (id: number|string) => {
  return fetchDelete(`/manager/instance/${id}`)
}

// 对象权限列表(实例列表-详情-详情)
export const objectPermissionList = (params: any) => {
  return fetchPost(`/manager/instance-tab-privs/page`, params)
}

// 系统权限(实例列表-详情-详情)
export const systemPrivateList = (params: any) => {
  return fetchPost(`/manager/instance-sys-privs/page`, params)
}

// 角色列表(实例列表-详情-详情)
export const roleList = (params: any) => {
  return fetchPost(`/manager/instance-role-privs/page`, params)
}

// 实例列表详情-应用列表
export const applicationList = (params: any) => {
  return fetchPost(`/manager/service/page`, params)
}

// 实例列表-数据同步
export const instanceSync = ({instanceId}: any) => {
  return fetchPost(`/manager/instance/account/${instanceId}`)
}

// 实例列表-账号诊断
export const instanceAccountDiagnose = (params: any) => {
  return fetchPost(`/manager/instance/diagnostic`, params)
}

// 实例列表-详情-诊断记录列表 
export const instanceDiagnoseReportList = (params: any) => {
  return fetchPost(`/manager/risk-view/page`, params)
}

// 参数设置-数据源类型信息查询
export const getDataSourceTypeInfo = (type: string) => {
  return fetchGet(`/manager/privileged-account-rule/type/${type}`)
}

// 参数设置-参数列表查询
export const getParameterList = () => {
  return fetchGet(`/manager/system-variables/list`)
}

// 参数设置-角色枚举
export const getRoleEnum = (datasourceType: string) => {
  return fetchGet(`/manager/instance-role-privs/sys/${datasourceType}`)
}

// 参数设置-系统权限枚举
export const getSystemPrivilegeEnum = (datasourceType: string) => {
  return fetchGet(`/manager/instance-sys-privs/sys/${datasourceType}`)
}

// 参数设置-修改参数列表
export const modifyParameter = (params: any) => {
  return fetchPut(`/manager/system-variables`, params)
}

// 参数设置-修改数据源信息
export const modifyDataSourceTypeInfo = (params: any) => {
  return fetchPost(`/manager/privileged-account-rule`, params)
}

// 标签列表查询
export const getTagList = (params: any) => {
  return fetchPost(`/manager/tag/page`, params)
}

// 标签-账号查询 id & name
export const getTagAccountList = (params: any) => {
  return fetchPost(`/manager/instance/account/tag`, params)
}

// 实例列表-账号列表-被授权人信息
export const getAccountAuthorized = (id: number|string) => {
  return fetchGet(`/manager/user/${id}`)
}

// 实例列表-账号列表-历史授权列表
export const getAccountAuthorizationHistory = (params: any) => {
  return fetchPost(`/manager/account-authorization-history/page`, params)
}

// 实例列表-账号列表-密码授权
export const passwordAuthorization = (params: any) => {
  return fetchPost(`/manager/instance/account/authorizedAccount`, params)
}

// 实例密码初始化
export const instancePasswordInit = async(params: any) => {
  const getKey = await getTransmissionPublicKey()
  params.dbPassword  = setEncrypt({ publicKey: getKey, password: params.dbPassword})
  return fetchPost(`/manager/instance/account/initializePwd`, params)
}

// 初始化密码测试连接
export const passwordTestConnection = async(params: any) => {
  const getKey = await getTransmissionPublicKey()
  params.dbPassword  = setEncrypt({ publicKey: getKey, password: params.dbPassword})
  return fetchPost(`/manager/instance/account/testConnection`, params)
}

// 实例列表-新增管理员
export const instanceListAddManage = (params: any) => {
  return fetchPost(`/manager/instance/contacts/true`, params)
}

// 实例列表-删除管理员
export const instanceListDeleteManage = (params: any) => {
  return fetchPost(`/manager/instance/contacts/false`, params)
}

// 实例列表-管理员查询
export const instanceListQueryManage = (params: any) => {
  return fetchPost(`/manager/instance/contacts`, params)
}

// 添加或编辑实例-测试连接
export const instanceTestConnection = (params: any) => {
  return fetchPost(`/manager/instance/valid`, params)
}

// 获取账号风险诊断信息
export const accountSessionAnalysis = (accountId: any) => {
  return fetchGet(`/manager/instance/account/diagnostic/${accountId}`)
}

// 账号标签编辑或新增
export const addOrEditTags = (params: any) => {
  return fetchPost(`/manager/tag-relation/editTag`, params)
}

// 账号解除绑定标签
export const unboundTags = (params: any) => {
  return fetchPost(`/manager/tag-relation/unbound`, params)
}

// 标签分析
export const tagAnalysis = (params: any) => {
  return fetchPost(`/manager/tag/accountAnalysis`, params)
}

// 会话分析设置查询
export const drawAnalysisSetting = (params: any) => {
  return fetchPost(`/manager/analysis/setting/page`, params)
}

// 改密设置
export const modifyPasswordSetting = (params: any) => {
  return fetchPost(`/manager/instance/account`, params)
}

// 查询改密信息
export const queryPasswordSetting = (id: string) => {
  return fetchGet(`/manager/instance/account/${id}`)
}

// 诊断报告列表
export const diagnosticReportList = (params?: any) => {
  return fetchPost(`/manager/diagnostic/report/page`, params)
}

// 诊断报告详情
export const diagnosticReportDetail = (id?: number|string) => {
  return fetchPost(`/manager/diagnostic/report/detail/${id}`)
}

// 账号数量统计
export const diagnosticAccoountNum = (params: any) => {
  return fetchPost(`/manager/diagnostic/report/count`, params)
}

// top10统计
export const diagnosticReportCount = (params: any) => {
  return fetchPost(`/manager/diagnostic/report/top10`, params)
}

// 获取账号风险数
export const diagnosticRiskAccount = (params: any) => {
  return fetchPost(`/manager/diagnostic/report/riskAccount`, params)
}

// 访问来源分布
export const diagnosticVisitSource = (params: any) => {
  return fetchPost(`/manager/diagnostic/report/visitSource`, params)
}

// 获取账号统计折线图
export const diagnosticAccountLineChart = (params: any) => {
  return fetchPost(`/manager/diagnostic/report/lineChart`, params)
}

// 获取拓扑图信息
export const diagnosticTopology = (params: any) => {
  return fetchPost(`/manager/diagnostic/report/topology`, params)
}

// 拓扑图对应的账号列表获取
export const diagnosticTopoAccountList = (params: any) => {
  return fetchPost(`/manager/diagnostic/report/topology/account`, params)
}

// 账号风险详情时间获取
export const diagnosticRiskViewTime = (id: any) => {
  return fetchGet(`/manager/risk-view/${id}`)
}

// 托管账号测试连接
export const testManageAccountConnection = (id: any) => {
  return fetchPost(`/manager/instance/account/testConnection/${id}`)
}
/**
 * 新增标签
 * 可以新增、删除、双击编辑
 */
import React, { useEffect, useRef, useState } from 'react';
import { Space, Input, Tag, Tooltip, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { addTag, modifyTag, deleteTag } from 'src/apis'
import { mapTagClassName } from 'src/utils'

interface IProps {
  width?: number;
  height?: number;
  title?: any;
  value?: any[];
  type?: string
  onChange?: (value: string[]) => void;
  closable?: boolean;
  selectTag?: any[];
  [p: string]: any
}
const AddTags = ({
  width=100, 
  height=30, 
  type='ACCOUNT',
  title=<span><PlusOutlined style={{marginRight: 4}} />新建标签</span>, 
  value=[], 
  onChange,
  closable=true,
  selectTag=[],
  handleTagSelect,
  handleRemoveTag
}: IProps) => {
  const [inputVisible, setInputVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [editInputIndex, setEditInputIndex] = useState(-1)
  const [editInputValue, setEditInputValue] = useState('')
  const inputRef = useRef<InputRef>(null)
  const editInputRef = useRef<InputRef>(null)

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus()
    }
  }, [inputVisible])

  useEffect(() => {
    editInputRef.current?.focus()
  }, [inputValue])

  const handleClose = (id: string) => {
    deleteTag(id).then((res: any)=>{
      if(res !== undefined){
        const newTags = value?.filter((item: any) => item?.id !== id)
        onChange?.(newTags)
        handleRemoveTag(id)
      }
    }).catch((err)=>{
      console.error('删除标签失败:', err)
    })
  }

  const showInput = () => {
    setInputVisible(true)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(e.target.value?.trim()?.length > 8){
      return message.error('标签最长8个字符')
    }
    setInputValue(e.target.value?.trim())
  }

  const handleInputConfirm = () => {
    if (inputValue && value?.map(i=>i?.name)?.indexOf(inputValue) === -1) {
      addTag({type, name:inputValue}).then((res: any)=>{
        if(res !== undefined){
          onChange?.([...value, {name: inputValue, id: res?.id}])
        }
      }).catch((err: any)=>{
        console.error('添加标签失败:', err)
      })
    }
    setInputVisible(false)
    setInputValue('')
  }

  const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditInputValue(e.target.value?.trim())
  }

  const handleEditInputConfirm = (id) => {
    const params = {
      id,
      name: editInputValue
    }
    modifyTag(params).then((res)=>{
      if(res !== undefined){
        const newTags = [...value];
        newTags[editInputIndex] = params
        onChange?.([...newTags]);
        setEditInputIndex(-1)
        setInputValue('')
      }
    }).catch((err)=>{
      console.error('修改标签失败:', err)
    })
  }

  const tagStyle = {
    height,
    lineHeight: `${height}px`,
    cursor: "pointer",
    marginInlineEnd: 0
  }

  const tagInputStyle: React.CSSProperties = {
    ...tagStyle,
    width,
    marginRight: 8,
  }

  const tagPlusStyle: React.CSSProperties = {
    ...tagStyle,
    width,
    background: '#fff',
    borderStyle: 'dashed',
    textAlign: 'center'
  }
  
  return (
    <Space size={[8, 8]} wrap>
      {/* 渲染部分 */}
      <Space size={[8, 8]} wrap>
        {value?.map((item: any, index:number) => {
          const {name, id} = item
          if (editInputIndex === index) {
            return (
              <Input
                ref={editInputRef}
                key={id}
                style={tagInputStyle}
                value={editInputValue}
                onChange={handleEditInputChange}
                onBlur={()=>handleEditInputConfirm(id)}
                onPressEnter={()=>handleEditInputConfirm(id)}
              />
            );
          }
          const isLongTag = name?.length > 20;
          const canOption = !item?.sys
          const tagElem = (
            <Tag
              key={id} 
              className={`options mrb4 ${mapTagClassName[index % 4]}`}
              closable={closable}
              color={selectTag?.map((i: any)=>i?.id)?.includes(id) ? 'blue' : ''}
              onClose={() => closable && handleClose(id)}
              onClick={()=>handleTagSelect(item)}
            >
              <span
                onDoubleClick={(e) => {
                  if(!canOption) return
                  setEditInputIndex(index);
                  setEditInputValue(name);
                  e.preventDefault();
                }}
              >
                {isLongTag ? `${name?.slice(0, 20)}...` : name}
              </span>
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={name} key={id}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
      </Space>
      {/* 添加部分 */}
      {inputVisible ? (
        <Input
          ref={inputRef}
          style={tagInputStyle}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
          maxLength={8}
        />
      ) : (
        <Tag style={tagPlusStyle} onClick={showInput}>
          {title}
        </Tag>
      )}
    </Space>
  )
} 
export default AddTags
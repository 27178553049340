import { fetchGet, fetchPost }  from './customFetch'

//  操作记录（审计日志）列表
interface ListParamsI {
  pageNumber: number
  pageSize: number
  [p: string]: any
}
export const getOptionList = (params: ListParamsI) => {
  return fetchPost(`/manager/audit/page`, params)
}

//  事件信息列表
export const getEventList = (params: ListParamsI) => {
  return fetchPost(`/manager/event/page`, params)
}


// 事件统计
export const getEventStatistics = () => {
  return fetchGet(`/manager/event/count`)
}
// 申请改密
import { Iconfont, TextCanVisibleAndCopy } from 'src/components'
import { Modal, Form, Button, Divider, Input, Select, Row, Col, message, Radio, DatePicker } from 'antd'
import { flowApply, generatePassword, getServiceList, getUsersList } from 'src/apis'
import { useEffect, useState } from 'react'
import moment from 'moment'
import dayjs from 'dayjs'
const { TextArea } = Input

interface IProps {
  open: boolean, 
  setOpen:(p: boolean)=>void, 
  data: any,
  [p: string]: any
}
const ApplyModifyPassword = (props: IProps) => {
  const {open, setOpen, data } = props
  const { id, datasourceType, dbVersion, label, dbUrl, dbUser } = data
  const [form] = Form.useForm();

  const [password, setPassword] = useState<string>('')
  const [applicationList, setApplicationList] = useState([])
  const [userList, setUserList] = useState([])
  const [noticeAppIds,setNoticeAppIds] = useState<string>('')
  const [btnLoading, setBtnLoading] = useState(false)
  const [showChangePasswordTime, setShowChangePasswordTime] = useState(false)

  // 查询应用服务列表
  const queryApplicationList = () => {
    const params={
      pageNumber:1,
      pageSize:10000,
    }
    getServiceList(params).then((res:any)=>{
      const data = res?.records?.map((item: any)=>({label: `${item?.appName}(${item?.host})`, value:item?.id}))
      setApplicationList(data)
    }).catch((err: any)=>{console.error('查询应用服务列表失败：', err)})
  }

  // 审批人列表查询
  const queryUserList = () => {
    const param = {
      pageNumber: 1,
      pageSize: 1000,
      userType: 'internal',
      roleType: 'security'
    }
    getUsersList(param).then((res: any)=>{
      const data = res?.records?.map((item: any)=>({label: item?.userName,value: item?.id}))
      setUserList(data)
    }).catch((err: any)=>{console.error('用户列表查询失败：', err)})
  }

  useEffect(()=>{
    queryUserList()
    // queryApplicationList()
    handleGeneratePassword() // 默认生成一个密码
  },[])

  const handleClose = () => {
    setOpen(false)
  }

  // 生成密码
  const handleGeneratePassword = () => {
    generatePassword().then((res)=>{
      setPassword(res)
      form.setFieldsValue({password: res})
    }).catch((err: any)=>{console.error('生成密码失败：', err)})
  }

  // 通知应用服务
  const handleNoticeAppChange = (value: any) => {
    const res = value?.length ? value?.join(',') : ''
    setNoticeAppIds(res)
  }

  const handleSave = () => {
    form.validateFields().then((values: any)=>{
      setBtnLoading(true)
      const { reason, approver, pwdChangeTime } = values
      const params: any = {
        accountId: id,
        dbPassword: password,
        flowType: 'APPLY_CHANGE_PWD',
        noticeAppIds,
        reason,
        approver: approver?.label,
        approverId: approver?.value,
      }
      if(pwdChangeTime){
        params.pwdChangeTime = dayjs(pwdChangeTime).format('YYYY-MM-DD HH:mm:ss')
      }
      flowApply(params).then((res: any)=>{
        if(res !== undefined){
          setOpen(false)
          message.success('操作成功')
        }
      }).catch((err: any)=>{console.error('申请改密失败：', err)})
      .finally(()=>{
        setBtnLoading(false)
      })
    }).catch((err: any)=>{
      console.error('form error', err)
    })
  }

  const handleFieldsChange = (changedFields: any) => {
    if(changedFields?.[0]?.name?.[0] === 'modifyType'){
      const modifyType = form.getFieldValue('modifyType')
      if(modifyType==='auto'){
        setShowChangePasswordTime(true)
      }else{
        setShowChangePasswordTime(false)
      }
    }
  }

  const disabledTime = (current: any) => {
    if (!current) {
      return {};
    }

    const currentHour = moment().utcOffset(480).hours();
    const currentMinute = moment().utcOffset(480).minutes();
    const currentSecond = moment().utcOffset(480).seconds();

    // 禁用当前日期之前的时间
    if (current.isSame(moment(), 'day')) {
      return {
        disabledHours: () => Array.from({ length: currentHour }, (_, i) => i),
        disabledMinutes: (selectedHour: number) => {
          if (selectedHour === currentHour) {
            return Array.from({ length: currentMinute }, (_, i) => i);
          }
          return [];
        },
        disabledSeconds: (selectedHour: number, selectedMinute: number) => {
          if (selectedHour === currentHour && selectedMinute === currentMinute) {
            return Array.from({ length: currentSecond }, (_, i) => i);
          }
          return [];
        },
      };
    }
  
    return {};
  };

  const formLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  const tailFormLayout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 12 },
  };

  return <Modal
    width={1000}
    title='申请改密'
    open={open} 
    footer={null}
    onCancel={handleClose}
    >
      <Divider style={{ margin:'10px 0' }}/>
      <Form
        {...formLayout}
        form={form}
        autoComplete="off"
        onFieldsChange={handleFieldsChange}
      > 
        <Row gutter={24}>
          <Col span={12} style={{paddingRight: 0}}>
            <Form.Item
              label='数据源类型'
              {...tailFormLayout}
            >
              <span>{datasourceType ? <Iconfont className='mr4' type={`icon-connection-${datasourceType}`} /> : ''}{datasourceType}</span>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="数据源版本"
            >
             {dbVersion}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label='实例标注'
        >
          {label}
        </Form.Item>
        <Form.Item
          label="实例地址端口"
        >
          {dbUrl}
          {/* <Space.Compact>
            <Form.Item
              name={'address1'}
              noStyle
            >
              <Input style={{width: 140}} />
            </Form.Item>
            <Form.Item
              name={'address2'}
              noStyle
            >
              <Input style={{width: 80}} />
            </Form.Item>
            <Form.Item
              name={'address3'}
              noStyle
            >
              <Input style={{width: 80}} />
            </Form.Item>
          </Space.Compact> */}
        </Form.Item>
        <Form.Item
          label='用户名'
        >
          {dbUser}
        </Form.Item>
        <Form.Item
          label="审批人"
          name="approver"
          rules={[{ required: true }]}
        >
          <Select 
            labelInValue
            style={{width: 300}} 
            options={userList} 
            placeholder='请选择'
          />
        </Form.Item>
        <Form.Item
          label="申请备注"
          name="reason"
        >
          <TextArea style={{width: 300}} placeholder='请输入' />
        </Form.Item>
        <Form.Item
          label=""
          name="modifyType"
          wrapperCol={{span: 18, offset:6}}
        >
          <Radio.Group>
            <Radio value={'auto'}>自动修改</Radio>
            <Radio value={'manual'}>手动修改</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="密码"
          name="password"
          rules={[{ required: true, message:'请点击生成密码' }]}
        >
          <span className='flexAlignCenter'>
            <TextCanVisibleAndCopy showCopy={false} text={password} />
            <span className='options ml10' onClick={handleGeneratePassword}>生成密码</span>
          </span>
        </Form.Item>
        {
          showChangePasswordTime &&
          <Form.Item
            label="改密时间"
            name="pwdChangeTime"
            rules={[{ required: true }]}
          >
            <DatePicker 
              showTime
              showNow={false}
              format={'YYYY-MM-DD HH:mm:ss'} 
              style={{width: 300}} 
              placeholder='请选择'
              disabledDate={(current) => current && current < moment().startOf('day')}
              disabledTime={disabledTime}
            />
          </Form.Item>
        }
      </Form>
      <Divider style={{margin:'10px 0'}} />
      <div className='flexEnd'>
        <Button className='mr10' onClick={handleClose}>取消</Button>
        <Button loading={btnLoading} type='primary' onClick={handleSave}>提交</Button>
      </div>
    </Modal>
}

export default ApplyModifyPassword
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserInfo } from 'src/types'

interface LoginState {
  isLogged: boolean
  userInfo: Partial<UserInfo>
}

const initialState: LoginState = {
  isLogged: false,
  userInfo: {},
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setUserInfo(state, action: PayloadAction<Partial<UserInfo>>) {
      state.userInfo = action.payload
    },
    setIsLogged(state, action: PayloadAction<boolean>) {
      state.isLogged = action.payload
    },
  },
})

export const loginReducer = loginSlice.reducer

export const {
  setUserInfo,
  setIsLogged,
} = loginSlice.actions


// 流程审批
import { fetchGet, fetchPost, fetchDelete }  from './customFetch'
import { getTransmissionPublicKey } from './login'
import { setEncrypt } from 'src/utils/encryptRsaSm2'

// 我的申请 各个tab页的数据; tabType: SUBMIT-APPROVING-CHANGE_PWD-COMPLETED
interface IListParams {
  pageNumber: number,
  pageSize: number,
  tabType: string
}
export const getApplyList = (params: IListParams) => {
  const {tabType, pageSize, pageNumber} = params
  return fetchGet(`/manager/flow/queryApply/${tabType}/${pageNumber}/${pageSize}`)
}

// 查询 我的审批 各个tab页的数据; tabType: SUBMIT-APPROVING-CHANGE_PWD-COMPLETED
export const getApprovalList = (params: IListParams) => {
  const {tabType, pageSize, pageNumber} = params
  return fetchGet(`/manager/flow/queryApproval/${tabType}/${pageNumber}/${pageSize}`)
}

interface IOptionParams {
  id: number
}
// 流程审批同意
export const flowApproival = (params: IOptionParams) => {
  return fetchPost(`/manager/flow/approval`, params)
}

// 流程拒绝 
export const flowReject = (params: IOptionParams) => {
  return fetchPost(`/manager/flow/reject`, params)
}

// 撤回流程申请
export const flowRevoke= (params: IOptionParams) => {
  return fetchPost(`/manager/flow/revoke`, params)
}

// 申请删除 
export const applyDelete= (id: number) => {
  return fetchDelete(`/manager/flow/${id}`)
}

// 提交流程申请
interface IFlowApplyParams {
  accountId: string
  dbPassword: string
  flowType: string
  noticeAppIds: string
  reason: string
  [p: string]: any
}
export const flowApply= async(params: IFlowApplyParams) => {
  const getKey = await getTransmissionPublicKey()
  params.dbPassword  = setEncrypt({ publicKey: getKey, password: params.dbPassword})
  return fetchPost(`/manager/flow/apply`, params)
}

// 提交申请
export const applySubmit= (id: number|string) => {
  return fetchPost(`/manager/flow/apply/${id}`)
}

// 我的申请查询
export const applyQuery = (params: any) => {
  return fetchPost(`/manager/flow/queryApply`, params)
}

// 我的审批查询
export const approvalQuery = (params: any) => {
  return fetchPost(`/manager/flow/queryApproval`, params)
}

// 我的申请待改密已完成
export const applyChangePwd = (params: any) => {
  return fetchPost(`/manager/password/queryApply`, params)
}

// 停止改密
export const stopModifyPassword = (id: number|string) => {
  return fetchPost(`/manager/password/stop/${id}`)
}

// 执行改密
export const executeModifyPassword = (id: number|string) => {
  return fetchPost(`/manager/password/execute/${id}`)
}

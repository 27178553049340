// 流文件下载
/**
 * 
 * @param url 
 * @param params 
 * @param fileName 
 */
export const downloadBlobFile = (url: string, fileName: string, params={}) => {
  const accessToken = localStorage.getItem('accessToken');
  fetch(url, {
      headers: {
        'content-type': 'application/json',
        'Authorization': accessToken!
      },
      method: 'POST',
      body: JSON.stringify(params),
      mode: 'cors',
      credentials: 'include'
  })
  .then((res) => {
    const filename = res.headers.get("content-disposition")?.split(";")[1]?.split("filename=")[1]! || fileName
    res.blob().then((blobRes)=>{
      const aElement = document.createElement("a");
      aElement.setAttribute("download", filename);
      const href = URL.createObjectURL(blobRes);   // 创建临时的URL对象
      aElement.href = href;
      aElement.setAttribute("target", "_blank");
      aElement.click();
      URL.revokeObjectURL(href);  // 释放临时的URL对象
    })
  });
}

/**
 * 标签管理弹框-账号
 */
import { Button, Divider, Modal, Tag, Tooltip } from "antd"
import { PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import AddTags from "./AddTags"
import { useEffect, useState } from "react"
import { getTagList } from 'src/apis'
import { mapTagClassName } from 'src/utils'
interface IProps {
  open: boolean,
  setOpen:(v: boolean)=>void
  callback?:(p:any, a: any)=>void
  [p: string]: any
}
export const TagManageModal = ({open, setOpen, callback, tagType='ACCOUNT', type='binding', initData, refresh}: IProps) => {
  const [tags, setTags] = useState<any[]>([])
  const [customTagSelect, setCustomTagSelect] = useState<any[]>([])  // 自定义标签选中
  const [accountTagSelect, setAccountTagSelect] = useState<any>({}) // 账号标签选中 

  useEffect(()=>{
    queryTags()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(()=>{
    if(initData && Object.keys(initData)?.length){
      const {tags, usage, isUnused, isSysBuilt} = initData
      setAccountTagSelect({
        usage,
        isUnused,
        isSysBuilt
      })
      setCustomTagSelect(tags)
    }
  },[initData])

  const queryTags = () => {
    const params = {
      type: tagType, 
      pageNumber: 1,
      pageSize: 1000,
    }
    getTagList(params).then((res: any)=>{
      const {records=[]} = res
      setTags(records)
    }).catch((err)=>{
      console.error('查询标签失败:', err)
    })
  }

  const handleSave = () => {
    callback && callback(customTagSelect, accountTagSelect)
    setOpen(false)
  }

  const handleTagsChange = (data: any) => {
    setTags(data)
    setCustomTagSelect((v: any[])=>v?.filter((i: any)=>data?.map((i:any)=>i?.id)?.includes(i?.id)))
  }

  const handleTagSelect = (item: any) => {
    setCustomTagSelect((value: any[])=>{
      if(value?.map(i=>i?.id)?.includes(item?.id)){
        return value?.filter((i: any)=>i?.id !== item?.id)
      }else{
        return [...value, item]
      }
    })
  }

  const handleRemoveTag = (id: any) => {
    setCustomTagSelect((value: any[])=>{
      return value?.filter((i: any)=>i?.id !== id)
    })
  }

  const handleRemoveAccountTag = (type: string) => {
    setAccountTagSelect((p: any)=>{
      if(type==='isUnused'){
        return {...p, isUnused: false}
      }else if(type==='isSysBuilt'){
        return {...p, isSysBuilt: false}
      }else{
        delete p.type
        return {...p, [type]: ''}
      }
    })
  }

  const handleAccountTag = (type: string, value: string|boolean) => {
    setAccountTagSelect((p: any)=>{
      if(type==='isUnused'){
        return {...p, isUnused: !p?.['isUnused']}
      }else if(type==='isSysBuilt'){
        return {...p, isSysBuilt: !p?.['isSysBuilt']}
      }else if(p[type] === value){
        delete p.type
        return {...p, [type]: ''}
      }else{
        return {...p, [type]: value}
      }
    })
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleComplete = () => {
    setOpen(false)
    refresh && refresh()
  }

  return (
    <Modal
      width={800}
      title={
        <span>
          <PlusOutlined className="mr4" />
          {
            type==='binding' ? '绑定标签' : '添加标签'
          }
        </span>
      }
      footer={null}
      open={open}
      onCancel={handleClose}
    >
      <Divider style={{margin: '20px 0'}} />
      {/* 绑定标签操作 */}
      {
        type === 'binding' && tagType !== 'SERVICE' &&
        <>
          <div style={{background: '#F6F6F6', padding:'10px 24px', color:'#667179'}} className="mt20 mb10">
            账号类型标签
            <Tooltip title='人用账号与机用账号标签只能选其一，标识当前账号使用分类。'>
              <QuestionCircleOutlined className='ml4' />
            </Tooltip>
          </div>
          <div className="flexAlignCenter mb10" style={{paddingLeft:'24px'}}>
            <Tag className="customTagGreen mrb4 options" onClick={()=>handleAccountTag("usage", "HUMAN_ACCOUNT")}>人用账号</Tag>
            <Tag className="customTagPurple mrb4 options" onClick={()=>handleAccountTag("usage", "MACHINE_ACCOUNT")}>机用账号</Tag>
            <Tag className="customTagBlue options" onClick={()=>handleAccountTag('isUnused', true)}>闲置账号</Tag>
          </div>
        </>
      }
      <div style={{background: '#F6F6F6', padding:'10px 24px', color:'#667179'}} className="mt20 mb10">
        自定义标签
      </div>
      <div style={{paddingLeft: 24}}>
        <AddTags
          type={tagType}
          onChange={handleTagsChange}
          value={tags}
          selectTag={customTagSelect}
          handleTagSelect={handleTagSelect}
          handleRemoveTag={handleRemoveTag}
        />
      </div>
      {/* 绑定标签操作 */}
      {
        type==='binding' &&
        <>
          <div style={{background: '#F6F6F6', padding:'10px 24px', color:'#667179'}} className="mt20 mb10">
            选中标签
          </div>
          <div style={{paddingLeft: 24}}>
            {/* 账号标签 */}
            {
              Object.keys(accountTagSelect)?.filter(i=>accountTagSelect[i] && [true, 'HUMAN_ACCOUNT', 'MACHINE_ACCOUNT'].includes(accountTagSelect[i]))?.map((i: string, index: number)=>{
                return (
                  <Tag 
                    key={i+'_'+accountTagSelect[i]} 
                    className={`options mrb4 ${mapTagClassName[index % 4]}`} 
                    closable={i === 'isSysBuilt' ? false : true}
                    onClose={()=>handleRemoveAccountTag(i)}
                  >
                    {
                      i === 'isUnused' ? '闲置账号' :
                      i === 'isSysBuilt' ? '内置账号' : 
                      accountTagSelect[i] === 'HUMAN_ACCOUNT' ? '人用账号' :
                      accountTagSelect[i] === 'MACHINE_ACCOUNT' ? '机用账号' : ''
                    }
                  </Tag>
                )
              })
            }
            {/* 自定义标签 */}
            {
              customTagSelect?.map((i: any, index: number)=>{
                return (
                  <Tag 
                    key={i?.id} 
                    className={`options mrb4 ${mapTagClassName[index % 4]}`} 
                    closable
                    onClose={()=>handleRemoveTag(i?.id)}
                  >
                    {i?.name}
                  </Tag>
                )
              })
            }
          </div>
        </>
      }
      
      {
        type==='binding' ?
          <div className="flexEnd mt20">
            <Button className="mr10" onClick={handleClose}>取消</Button>
            <Button type="primary" onClick={handleSave}>确定</Button>
          </div>
        : <Button className="flexEnd mt20" type="primary" onClick={handleComplete}>完成</Button>
      }
    </Modal>
  ) 
}
export default TagManageModal
import { Table } from 'antd'

export const CustomTable = (props:any) => {
  const { dataSource } = props

  // 统一处理table y轴滚动条问题
  if(dataSource?.length === 0){
    delete props.scroll?.y
  }

  return (
    <Table {...props} />
  )
}
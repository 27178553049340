import { Tabs } from 'antd'
import styles from './index.module.scss'

const RightTopMenu = ({tabs, activeKey, setActiveKey}) => {
  return (
    <div className={styles.topMenu}>
      <Tabs
        items={tabs}
        activeKey={activeKey}
        onChange={setActiveKey}
      />
    </div>
  )
}

export default RightTopMenu
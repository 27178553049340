// 计算table滚动宽度
export const getScrollX = (columns: any[])=> columns.reduce((pre: number, cur: any)=>{
  return pre + cur?.width
},100) || 1100

export const renderTableFields = (node: any)=>node ? node : '-'

export const ToLowerCase = (txt: string|number) =>txt?.toString()?.toLowerCase()
export const ToUpperCase = (txt: string|number) =>txt?.toString()?.toUpperCase()

export const PieColorMap = ['#3357FF', '#9D58FF', '#90FFCD', '#6DA5F1', '#67CAE9', '#59CFC3', '#84F9E0', '#b8f3e7', '#076E96']

export const mapTagClassName = {
  '0': 'customTagGreen',
  '1': 'customTagPurple',
  '2': 'customTagBlue',
  '3': 'customTagYellow',
}


// 左侧菜单key
export const LeftMenuKeys = [
  '/workbench',
  '/databaseAccountManagement',
  '/systemServiceManagement',
  '/proxyServiceManagement',
  '/processApprovalManagement',
  '/userManagement',
  '/auditManagement',
  '/systemSetting'
]

// 左侧菜单子菜单配置
export const LeftMenuChildrenMap = {
  // 概览
  '/workbench': [
    {
      key: '/workbench',
      label: '概览',
    },
  ],
  // 数据库账号管理
  '/databaseAccountManagement': [
    {
      key: '/databaseAccountManagement/instanceList',
      label: '实例列表',
    },
    {
      key: '/databaseAccountManagement/accountList',
      label: '账号列表',
    },
     {
      key: '/databaseAccountManagement/tagManage',
      label: '标签管理',
    },
     {
      key: '/databaseAccountManagement/paramsSetting',
      label: '参数设置',
    },
  ],

  // 系统服务管理
  '/systemServiceManagement': [
    {
      key: '/systemServiceManagement/serverAnalysis',
      label: '服务分析',
    },
    {
      key: '/systemServiceManagement/systemServer',
      label: '系统服务',
    },
  ],

  // 代理服务管理
  '/proxyServiceManagement': [
    {
      key: '/proxyServiceManagement',
      label: '代理服务',
    }
  ],

  // 流程审批
  '/processApprovalManagement': [
    {
      key: '/processApprovalManagement/myApply',
      label: '我的申请',
    },
    {
      key: '/processApprovalManagement/myApproval',
      label: '我的审批',
    }
  ],

  // 用户管理
  '/userManagement': [
    {
      key: '/userManagement/systemUser',
      label: '系统用户',
    },
    {
      key: '/userManagement/addressBookUser',
      label: '通讯录',
    }
  ],

  // 审计
  '/auditManagement': [
    {
      key: '/auditManagement/operational',
      label: '操作审计',
    },
    {
      key: '/auditManagement/eventInfo',
      label: '事件信息',
    }
  ],

  // 系统设置
  '/systemSetting': []
}
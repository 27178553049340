/**
 * 自定义标签渲染
 * showTagLength：默认最多展示两个标签
 */
import { Tooltip, Tag } from 'antd'
import { PlusOutlined } from '@ant-design/icons' 
import { mapTagClassName } from 'src/utils'

interface IProps {
  data: any
  canAdd?: boolean
  callback?:(p: any)=>void
  showTagLength?: number // 最多展示标签数量
  [p: string]: any
}
export const CustomTagRender = ({data, canAdd, callback, showTagLength=2 }: IProps) => {
  const usage = ['HUMAN_ACCOUNT', 'MACHINE_ACCOUNT'].includes(data?.usage) ? 1: 0
  const sAccount = (data?.isSysBuilt && data?.isUnused) ? 2 : (data?.isSysBuilt || data?.isUnused) ? 1 : 0
  const showCustomLength = sAccount + usage >= showTagLength ? 0 : showTagLength - sAccount - usage 
  const moreLength =  data?.tags?.length + sAccount + usage - showTagLength

  return (
    <span>
      {/* 账号标签渲染：闲置账号、内置账号、人用账号、机用账号 */}
      {
        data?.isUnused ? <Tag className={`mrb4 ${mapTagClassName[0]}`}>闲置账号</Tag> : ''
      }
      {
        data?.isSysBuilt ? <Tag className={`mrb4 ${mapTagClassName[1]}`}>内置账号</Tag> : ''
      }
      {
        (sAccount < 2) ? (
          data?.usage === 'HUMAN_ACCOUNT' ? <Tag className={`mrb4 ${mapTagClassName[2]}`}>人用账号</Tag> : 
          data?.usage === 'MACHINE_ACCOUNT' ? <Tag className={`mrb4 ${mapTagClassName[3]}`}>机用账号</Tag> : ''
        ) : ''
      }
     
      {/* 自定义标签渲染 */}
      {
        data?.tags?.length > showCustomLength ?
          <Tooltip 
          title={
            <>
              {
                data?.isUnused ? <Tag className={`mrb4 ${mapTagClassName[0]}`}>闲置账号</Tag> : ''
              }
              {
                data?.isSysBuilt ? <Tag className={`mrb4 ${mapTagClassName[1]}`}>内置账号</Tag> : ''
              }
              {
                data?.usage === 'HUMAN_ACCOUNT' ? <Tag className={`mrb4 ${mapTagClassName[1]}`}>人用账号</Tag> : 
                data?.usage === 'MACHINE_ACCOUNT' ? <Tag className={`mrb4 ${mapTagClassName[2]}`}>机用账号</Tag> : ''
              }
              {
                data?.tags?.map((item: any, index: number)=><Tag key={'tooltip_'+index} className={`mrb4 ${mapTagClassName[index % 4]}`}>{item?.name}</Tag>)
              }
            </>
          }
        >
          {
            data?.tags?.filter((_, index)=>index<showCustomLength)?.map((item: any, index:number)=><Tag key={'show_'+index} className={`mrb4 ${mapTagClassName[index % 4]}`}>{item?.name}</Tag>)
          }
          <span className='plusNumIcon options mr4'>+{moreLength}</span>
        </Tooltip>
        : data?.tags?.length ? 
          data?.tags?.map((item: any, index:number)=><Tag key={index} className={`mrb4 ${mapTagClassName[index % 4]}`}>{item?.name}</Tag>) 
          : ''
      }
      {
        canAdd && 
        <PlusOutlined className='plusIcon ml4' onClick={callback} />
      }
    </span>
  )
}
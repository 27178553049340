import { fetchDelete, fetchGet, fetchPost, fetchPut, fetchPostFormData }  from './customFetch'

interface IListParams {
  pageNumber: number,
  pageSize: number,
  [p: string]: any
}

// 应用服务列表查询
export const getServiceList = (params: IListParams) => {
  return fetchPost(`/manager/service/page`, params)
}

// 应用系统列表查询
export const getSystemList = (params: IListParams) => {
  return fetchPost(`/manager/system/page`, params)
}

// 删除系统列表
export const deleteSystemItem = (id: number|string) => {
  return fetchDelete(`/manager/system/${id}`)
}

// 删除服务列表
export const deleteServiceItem = (id: number|string) => {
  return fetchDelete(`/manager/service/${id}`)
}

// 更新服务列表
export const updateServiceList = (params: any) => {
  return fetchPost(`/manager/service`, params)
}

// 根据id查询账户列表
export const getDetailAccountList = (params: IListParams) => {
  const { id, pageNumber, pageSize } = params
  return fetchGet(`/manager/application/account/${id}/${pageNumber}/${pageSize}`)
}
// 根据id查询用户列表
export const getDetailUserList = (params: IListParams) => {
  const { id, pageNumber, pageSize } = params
  return fetchGet(`/manager/application/contact/${id}/${pageNumber}/${pageSize}`)
}

// 添加应用
export const addApplication = (params: any) => {
  return fetchPut(`/manager/application`, params)
}

// 关联帐号保存 
export const saveRelationAccount = (params: any) => {
  return fetchPost(`/manager/application`, params)
}

// 数据库帐号列表查询 
export const relationAccountList = () => {
  return fetchGet(`/manager/application/page/1/1000`)
}

// 测试网络
export const testStatus = (ip: string) => {
  return fetchGet(`/manager/application/network?ip=${ip}`)
}

// 系统服务-系统注册
export const systemRegister = (params: any) => {
  return fetchPost(`/manager/system/register`, params)
}

// 服务账号信息查询
export const getAccountList = (params: any) => {
  return fetchPost(`/manager/session/accounts`, params)
}

// 标签新增
interface ITagParams {
  name: string,
  type: string
}
export const addTag = (params: ITagParams) => {
  return fetchPut(`/manager/tag`, params)
}

// 修改标签
interface IModifyTagParams {
  id: string|number,
  name: string
}
export const modifyTag = (params: IModifyTagParams) => {
  return fetchPost(`/manager/tag`, params)
}

// 删除标签
export const deleteTag = (id: string|number) => {
  return fetchDelete(`/manager/tag/${id}`)
}

// 根据id查询系统信息
export const getSystemInfo = (id: string|number) => {
  return fetchGet(`/manager/system/${id}`)
}

// 复制配置信息
export const copyConfig = (params: any) => {
  return fetchPost(`/manager/service/copyConfig`, params)
}

// 删除系统服务服务列表
export const deleteService = (id: number|string) => {
  return fetchDelete(`/manager/service/${id}`)
}

// 服务账号信息查询
export const queryServiceAccountList = (params: any) => {
  return fetchPost(`/manager/service/accounts`, params)
}

// 系统导入
export const systemImport = (params: any) => {
  return fetchPostFormData(`/manager/system/import`, params)
}

// 统计数量查询
export const queryInstAccountCount = (ip: any) => {
  return fetchGet(`/manager/service/count/${ip}`)
}